@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap');


.main_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    min-height: 100vh;
    min-width: 100vw;
}
.main_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.main_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    /* height: 80vh; */
    /* max-width: 1200px; */
    width: 60vw;
    background-color: rgba(0,0,0,.5);
    padding: 3em 1.6em 0;
}
.big_logo {
    width: 50vw;
}
.main_headline {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 6em;
    color: rgb(255,255,255);
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    text-shadow: rgba(0,0,0,1) .04em .04em;
}
.main_headline_2 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 2.5em;
    color: rgb(255,255,255);
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    text-shadow: rgba(0,0,0,1) .04em .04em;
    text-align: center;
    max-width: 12em;
}
.main_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: .05em;
    color: rgb(255,255,255);   
    line-height: 1.3em;
    margin: 1.5em 0 0;
    text-shadow: rgba(0,0,0,1) .05em .05em;
    max-width: 18em;
    text-align: center;
}
.main_button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .9em;
    background-image: linear-gradient(#13c1df, #0f3381);
    border-radius: 1em;
    border: 1px rgb(0,0,0) solid;
    color: rgb(255,255,255);
    transition: all 0.3s;
    margin: 1.75em 0;
    line-height: 3;
    text-decoration: none;
    text-align: center;
    width: 14em;    
}
.main_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}





/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .main_section_background {
        background-attachment: scroll;  
    }
    .main_content_container {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,.6);
        padding: 0 0;
    }
    .main_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;        
    }
    .main_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1.8em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        max-width: 22rem;
    }
    .main_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;      
        max-width: 20rem;
    }
    .big_logo {
        width: 20rem;
    }

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .main_section_background {
        background-attachment: scroll;  
    }
    .main_content_container {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,.6);
        padding: 0 0;
    }
    .main_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.1em;
        line-height: 1em;        
    }
    .main_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        max-width: 14rem;
    }
    .main_copy {       
        font-family: 'Montserrat', sans-serif;
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;      
        max-width: 14rem;
    }
    .big_logo {
        width: 14rem;
    }

}