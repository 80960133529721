@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap');


.buy_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-color: rgb(214, 172, 123);
}
.buy_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.buy_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    padding: 3em 0;    
}
.buy_headline {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 6em;
    font-style: italic;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    color: rgb(99, 15, 88);
}
.buy_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .05rem;
    color: rgb(99, 15, 88);
    margin: 1.5em 0 0;
    max-width: 42rem;
}
.buy_button_copy {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 2.7em;
    font-weight: 700;
    color: rgb(99, 15, 88);
    letter-spacing: .02rem;
    line-height: 1.1em;
    margin: 1.5em 0 0;
    max-width: 27em;
}


/*---------------------MEDIA QUERIES------------------------*/
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 40rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 40rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }    
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 40rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 40rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 30rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 30rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }
   

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 1.9em;
        line-height: 1em;
        letter-spacing: .03rem;
        padding: 0 1rem;
        max-width: 14rem;        
    }
   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;      
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 15rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }
 

}
