@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.nav-bar {
    position: fixed;
    text-align: right;
    width: 100vw;
    background-image: linear-gradient(to right, rgb(18, 18, 18), rgb(0, 0, 0));
    color: white;
    padding-top: 8px;
    padding-bottom: 6px;
    z-index: 1;
}

.nav-links {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .75rem;
    list-style: none;
    padding: 1rem 1rem 1.2rem 1rem;
    margin-right: 1em;
}   
.nav-links-last {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .7em;
    list-style: none;
    padding: 5px 20px 10px 10px;
    margin-right: 1em;
}   

.nav-bar > .active {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: rgb(219, 84, 31);
    border-bottom: 2px solid white;    
}

.nav_logo {
    width: 5rem;
    float: left;
    padding-left: 1rem;
}



/*------------------------MEDIA QUERIES---------------------------*/



/*-----------LAPTOP and MOBILE------------*/
@media screen and (max-width: 1024px) and (min-width: 100px){ 
    .nav-bar {
        position: fixed;
        background-color: rgba(0,0,0,.2);
        background-image: none;
    }
    .nav-links {
        display: none;
        width: 100vw;
    }
    .nav_logo {
        width:80px;
        float: left;
        padding: 0 12px;
    }
    
}