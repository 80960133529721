@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap');


.about_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height:50rem;
    width: 100vw;
}
.about_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.about_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    /* height: 80vh; */
    /* max-width: 1200px; */
    width: 80vw;
    min-height: 50rem;
}
.about_headline {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 900;
    font-size: 8em;
    color: rgb(255,255,255);
    text-shadow: rgba(0,0,0,1) .04em .04em;
    letter-spacing: .25rem;
    line-height: 1em;
}
.about_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(255,255,255);
    text-shadow: rgba(0,0,0,1) .04em .04em;
    margin: 1.5em 0 0;
    max-width: 30em;
}




/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }

}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.3em;
        letter-spacing: .05em; 
        padding: 0 4rem 0;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 4rem;    
        max-width: 14rem;
    }
}