
.pg_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    width: 100vw;
    background-color: rgba(0,0,0,1);
}
/* .pg_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
} */

/*------------------ PHOTOGALLERY GRID CODE -------------------------*/
/* -------------- DESKTOP PHOTO GALLERY GRID CODE - (Min Width 1500px)------------------------------------- */
.pg_grid_container {
    display: grid;
    grid-template-columns: repeat(5,1fr); /* 4 column spaces and we'll see 1 fraction "visual columns" per column space */
    grid-auto-rows: 22rem; /* each row will alternate with these heights */
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; 
    justify-items: stretch;
    align-items: stretch;
    margin: 4rem;
}
.pg_grid_box {
    width: 100%;
    height: 100%;
    /* overflow:hidden hides parts of images that cross over another */
    overflow: hidden;
    position: relative;
}
.pg_grid_image {
    width: 100%;
    height: 100%;
    /* object-fit:cover makes image auto size itself and fills in space */
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.pg_grid_image:hover {
    /* makes images pop bigger */
    transform:scale(1.25);
    transition: .2s ease-in-out;
}


/*---------------------MEDIA QUERIES------------------------*/
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(4,1fr); 
        grid-auto-rows: 19rem;         
    } 
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(3,1fr); 
        grid-auto-rows: 18rem;         
    } 

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(2,1fr); 
        grid-auto-rows: 16rem;         
    } 

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(1,1fr); 
        grid-auto-rows: 12rem;         
    } 

}
