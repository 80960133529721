


.footer_section {
    background-color: rgb(5, 5, 5);
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch; 
    width: 100vw;
}
.footer_content {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: rgb(165, 158, 158);
    margin: 0 auto;
    padding: .3rem;
    text-align: center;
}
.footer_gap {
    padding-left: .3rem;
    padding-right: .3rem;
}
.logo_img {
    height: 4rem;
    margin: 2rem 0 0;
}

.back_to_top {    
    padding: 2rem; 
    margin: 0 auto; 
    cursor: pointer;;
}
.yellow {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: rgba(230, 213, 67, 1);
    text-decoration: none;
}


/*------------------------------MEDIA QUERIES------------------------------------*/

/*---------------LARGE MOBILE--------------------*/
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .logo_img {
        height: 3.2rem;
        margin: 1rem 0 0;
    }
    .footer_content {     
        font-size: 1em;       
    }
    .back_to_top {    
        padding: 1rem; 
        margin: 0 auto; 
        cursor: pointer;;
    }
    .yellow {
        font-size: .8em;        
    }

}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .logo_img {
        height: 3rem;
        margin: 1rem 0 0;
    }
    .footer_content {     
        font-size: .7em;       
    }
    .back_to_top {    
        padding: 1rem; 
        margin: 0 auto; 
        cursor: pointer;;
    }
    .yellow {
        font-size: .7em;        
    }

}