@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap);
body {
    margin: 0;
    padding: 0;
}
.nav-bar {
    position: fixed;
    text-align: right;
    width: 100vw;
    background-image: linear-gradient(to right, rgb(18, 18, 18), rgb(0, 0, 0));
    color: white;
    padding-top: 8px;
    padding-bottom: 6px;
    z-index: 1;
}

.nav-links {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .75rem;
    list-style: none;
    padding: 1rem 1rem 1.2rem 1rem;
    margin-right: 1em;
}   
.nav-links-last {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .7em;
    list-style: none;
    padding: 5px 20px 10px 10px;
    margin-right: 1em;
}   

.nav-bar > .active {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: rgb(219, 84, 31);
    border-bottom: 2px solid white;    
}

.nav_logo {
    width: 5rem;
    float: left;
    padding-left: 1rem;
}



/*------------------------MEDIA QUERIES---------------------------*/



/*-----------LAPTOP and MOBILE------------*/
@media screen and (max-width: 1024px) and (min-width: 100px){ 
    .nav-bar {
        position: fixed;
        background-color: rgba(0,0,0,.2);
        background-image: none;
    }
    .nav-links {
        display: none;
        width: 100vw;
    }
    .nav_logo {
        width:80px;
        float: left;
        padding: 0 12px;
    }
    
}
.main_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    min-height: 100vh;
    min-width: 100vw;
}
.main_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.main_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    /* height: 80vh; */
    /* max-width: 1200px; */
    width: 60vw;
    background-color: rgba(0,0,0,.5);
    padding: 3em 1.6em 0;
}
.big_logo {
    width: 50vw;
}
.main_headline {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 6em;
    color: rgb(255,255,255);
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    text-shadow: rgba(0,0,0,1) .04em .04em;
}
.main_headline_2 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 2.5em;
    color: rgb(255,255,255);
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    text-shadow: rgba(0,0,0,1) .04em .04em;
    text-align: center;
    max-width: 12em;
}
.main_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: .05em;
    color: rgb(255,255,255);   
    line-height: 1.3em;
    margin: 1.5em 0 0;
    text-shadow: rgba(0,0,0,1) .05em .05em;
    max-width: 18em;
    text-align: center;
}
.main_button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: .9em;
    background-image: linear-gradient(#13c1df, #0f3381);
    border-radius: 1em;
    border: 1px rgb(0,0,0) solid;
    color: rgb(255,255,255);
    transition: all 0.3s;
    margin: 1.75em 0;
    line-height: 3;
    text-decoration: none;
    text-align: center;
    width: 14em;    
}
.main_button:hover {
    background:rgb(255,255,255);
    color: rgb(0,0,0); 
    cursor: pointer;
}





/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .main_section_background {
        background-attachment: scroll;  
    }
    .main_content_container {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,.6);
        padding: 0 0;
    }
    .main_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;        
    }
    .main_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1.8em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        max-width: 22rem;
    }
    .main_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;      
        max-width: 20rem;
    }
    .big_logo {
        width: 20rem;
    }

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .main_section_background {
        background-attachment: scroll;  
    }
    .main_content_container {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,.6);
        padding: 0 0;
    }
    .main_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.1em;
        line-height: 1em;        
    }
    .main_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        max-width: 14rem;
    }
    .main_copy {       
        font-family: 'Montserrat', sans-serif;
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;      
        max-width: 14rem;
    }
    .big_logo {
        width: 14rem;
    }

}


.common_button {
    font-family: 'Montserrat', sans-serif;
    font-size: .9em;
    font-weight: 400;
    background-image: linear-gradient(rgb(19,193,223), rgb(16, 46, 110));
    border-radius: .5em;
    border: 1px rgb(0,0,0) solid;
    color: rgb(255,255,255);
    transition: all 0.3s;
    margin: 2rem 0 4rem;
    padding: .5rem;
    line-height: 3;
    text-decoration: none;
    text-align: center;
    width: 14em;    
}
.common_button:hover {
    background-image: linear-gradient(rgb(16,46,110), rgb(15, 65, 173));
    color: rgb(255,255,255);
    border: 1px rgb(0,0,0) solid;
}
.common_button:active {
    background-image: linear-gradient(rgb(15, 143, 47), rgb(15,143,47));
    color: rgb(255, 255, 255);
    border: 1px rgb(0, 0, 0) solid;
}
.button_copy {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .07rem;
    line-height: 1.1em;
    margin: 0 0;
    max-width: 27em;
}



/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){ 
    .common_button {       
        margin: 2rem 1rem;   
    }
}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .common_button {       
        margin: 1rem;        
    }
}


.pg_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    width: 100vw;
    background-color: rgba(0,0,0,1);
}
/* .pg_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
} */

/*------------------ PHOTOGALLERY GRID CODE -------------------------*/
/* -------------- DESKTOP PHOTO GALLERY GRID CODE - (Min Width 1500px)------------------------------------- */
.pg_grid_container {
    display: grid;
    grid-template-columns: repeat(5,1fr); /* 4 column spaces and we'll see 1 fraction "visual columns" per column space */
    grid-auto-rows: 22rem; /* each row will alternate with these heights */
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; 
    justify-items: stretch;
    align-items: stretch;
    margin: 4rem;
}
.pg_grid_box {
    width: 100%;
    height: 100%;
    /* overflow:hidden hides parts of images that cross over another */
    overflow: hidden;
    position: relative;
}
.pg_grid_image {
    width: 100%;
    height: 100%;
    /* object-fit:cover makes image auto size itself and fills in space */
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}
.pg_grid_image:hover {
    /* makes images pop bigger */
    transform:scale(1.25);
    transition: .2s ease-in-out;
}


/*---------------------MEDIA QUERIES------------------------*/
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(4,1fr); 
        grid-auto-rows: 19rem;         
    } 
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(3,1fr); 
        grid-auto-rows: 18rem;         
    } 

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(2,1fr); 
        grid-auto-rows: 16rem;         
    } 

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){
    .pg_grid_container {
        margin: 2rem; 
        grid-template-columns: repeat(1,1fr); 
        grid-auto-rows: 12rem;         
    } 

}

.buy_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-color: rgb(214, 172, 123);
}
.buy_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.buy_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;
    padding: 3em 0;    
}
.buy_headline {
    font-family: 'Playfair Display', serif;
    font-weight: 900;
    font-size: 6em;
    font-style: italic;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0;
    color: rgb(99, 15, 88);
}
.buy_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .05rem;
    color: rgb(99, 15, 88);
    margin: 1.5em 0 0;
    max-width: 42rem;
}
.buy_button_copy {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 2.7em;
    font-weight: 700;
    color: rgb(99, 15, 88);
    letter-spacing: .02rem;
    line-height: 1.1em;
    margin: 1.5em 0 0;
    max-width: 27em;
}


/*---------------------MEDIA QUERIES------------------------*/
/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 40rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 40rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }    
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 40rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 40rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 4em;
        line-height: 1em;
        letter-spacing: .15rem;
        padding: 0 1rem;
        max-width: 30rem;        
    }   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 30rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }
   

}

/* -------------------- SMALL PHONES ------------- */
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .buy_section_background {
        background-attachment: scroll;  
    }
    .buy_content_container {
        height: 100vh;
    }
    .buy_headline {
        font-family: 'Playfair Display', serif;
        font-size: 1.9em;
        line-height: 1em;
        letter-spacing: .03rem;
        padding: 0 1rem;
        max-width: 14rem;        
    }
   
    .buy_copy {  
        font-family: 'Montserrat', sans-serif;      
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        padding: 0 1rem ;      
        max-width: 15rem;
    }
    .buy_button_copy {
        font-family: 'Playfair Display', serif;
        font-weight: 400;
        font-size: 1.3em;
        padding: 0 1rem;
        max-width: 14rem;
    }
 

}

.about_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height:50rem;
    width: 100vw;
}
.about_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.about_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    /* height: 80vh; */
    /* max-width: 1200px; */
    width: 80vw;
    min-height: 50rem;
}
.about_headline {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 900;
    font-size: 8em;
    color: rgb(255,255,255);
    text-shadow: rgba(0,0,0,1) .04em .04em;
    letter-spacing: .25rem;
    line-height: 1em;
}
.about_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(255,255,255);
    text-shadow: rgba(0,0,0,1) .04em .04em;
    margin: 1.5em 0 0;
    max-width: 30em;
}




/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE TABLET ------------- */
@media screen and (max-width: 1200px) and (min-width: 992px) {
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }
}

/* -------------------- SMALL TABLET ------------- */
@media screen and (max-width: 991.5px) and (min-width: 768px){    
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }

}

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 5em;
        letter-spacing: .05em; 
        padding: 0 0 0 6rem;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: 1.5em;
        letter-spacing: .05em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 0 0 6rem;    
        max-width: 28rem;
    }

}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .about_section_background {
        background-attachment: scroll;          
    }
    .about_content_container {
        background-color: rgba(0,0,0,.5);
        padding: 0 0;
        margin: 0 0;
        width: 100vw;
        height: 100vh;        
    }
    .about_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.3em;
        letter-spacing: .05em; 
        padding: 0 4rem 0;       
    }
    .about_copy {   
        font-family: 'Montserrat', sans-serif;    
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;  
        padding: 0 4rem;    
        max-width: 14rem;
    }
}
.contact_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-color: rgb(173, 200, 216);
}
.contact_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.contact_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;    
}
.contact_headline {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 8em;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: 0 0;
    color: rgb(0,0,0);
}
.contact_headline_2 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 4em;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0 0;
    color: rgb(0,0,0);
}
.contact_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .12rem;
    line-height: 1em;
    margin: 1rem 0;
    max-width: 30em;
}
.contact_ul {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .12rem;
    line-height: 1.2em;
    margin: 0 0;
    max-width: 30em;
}
.bold_this {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 1.1em;
}




/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .contact_section_background {
        background-attachment: scroll;  
    }
    .contact_content_container {
        width: 100vw;
        height: 100vh;
    }
    .contact_headline {
        font-family: 'Playfair Display', serif;
        font-size: 6em;
        line-height: 1em;
        letter-spacing: .15rem;        
        padding: 0 0 0 6rem; 
        max-width: 30rem;       
    }
    .contact_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 2em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        padding: 0 0 0 6rem;
        max-width: 40rem;
    }
    .contact_ul {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;             
        padding: 0 0 0 6rem;  
        max-width: 26rem;      
    } 
    .contact_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.4em;
        letter-spacing: .03em;  
        line-height: 1.4em;
        margin: .6rem 0 0;      
        max-width: 30rem;
        padding: 0 0 0 3rem;
    }
   
}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .contact_section_background {
        background-attachment: scroll;  
    }
    .contact_content_container {
        width: 100vw;
        height: 100vh;
    }
    .contact_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.1em;
        line-height: 1em;
        letter-spacing: .15rem;
        max-width: 14rem; 
        padding: 0 0 0 3rem;       
    }
    .contact_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1em;   
        letter-spacing: .01em;
        line-height: 1em;
        margin: .15rem 0;
        padding: 0 0 0 3rem;
        max-width: 22rem;
    }
    .contact_ul {     
        font-family: 'Montserrat', sans-serif;    
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;             
        padding: 0 0 0 4rem;        
    } 
    .contact_copy {   
        font-family: 'Montserrat', sans-serif;      
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;     
        padding: 0 0 0 2em;
        max-width: 22rem;
    } 
  

}



.footer_section {
    background-color: rgb(5, 5, 5);
    display: flex;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    align-content: stretch; 
    width: 100vw;
}
.footer_content {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: rgb(165, 158, 158);
    margin: 0 auto;
    padding: .3rem;
    text-align: center;
}
.footer_gap {
    padding-left: .3rem;
    padding-right: .3rem;
}
.logo_img {
    height: 4rem;
    margin: 2rem 0 0;
}

.back_to_top {    
    padding: 2rem; 
    margin: 0 auto; 
    cursor: pointer;;
}
.yellow {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: rgba(230, 213, 67, 1);
    text-decoration: none;
}


/*------------------------------MEDIA QUERIES------------------------------------*/

/*---------------LARGE MOBILE--------------------*/
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .logo_img {
        height: 3.2rem;
        margin: 1rem 0 0;
    }
    .footer_content {     
        font-size: 1em;       
    }
    .back_to_top {    
        padding: 1rem; 
        margin: 0 auto; 
        cursor: pointer;;
    }
    .yellow {
        font-size: .8em;        
    }

}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .logo_img {
        height: 3rem;
        margin: 1rem 0 0;
    }
    .footer_content {     
        font-size: .7em;       
    }
    .back_to_top {    
        padding: 1rem; 
        margin: 0 auto; 
        cursor: pointer;;
    }
    .yellow {
        font-size: .7em;        
    }

}
