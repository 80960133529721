@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Playfair+Display:wght@700;900&display=swap');


.contact_section {    
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 100vh;
    min-height: 50rem;
    width: 100vw;
    background-color: rgb(173, 200, 216);
}
.contact_section_background {
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-attachment: fixed;  
}
.contact_content_container {
    margin: 0 auto;
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: stretch;
    width: 80vw;    
}
.contact_headline {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 8em;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: 0 0;
    color: rgb(0,0,0);
}
.contact_headline_2 {
    font-family: 'Playfair Display', serif;
    font-style: italic;
    font-weight: 700;
    font-size: 4em;
    letter-spacing: .25rem;
    line-height: 1em;
    margin: .25em 0 0;
    color: rgb(0,0,0);
}
.contact_copy {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .12rem;
    line-height: 1em;
    margin: 1rem 0;
    max-width: 30em;
}
.contact_ul {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 1.4em;
    color: rgb(0,0,0);
    letter-spacing: .12rem;
    line-height: 1.2em;
    margin: 0 0;
    max-width: 30em;
}
.bold_this {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    font-size: 1.1em;
}




/*------------------------------MEDIA QUERIES------------------------------------*/

/* -------------------- LARGE PHONES ------------- */
@media screen and (max-width: 767.5px) and (min-width: 480px){
    .contact_section_background {
        background-attachment: scroll;  
    }
    .contact_content_container {
        width: 100vw;
        height: 100vh;
    }
    .contact_headline {
        font-family: 'Playfair Display', serif;
        font-size: 6em;
        line-height: 1em;
        letter-spacing: .15rem;        
        padding: 0 0 0 6rem; 
        max-width: 30rem;       
    }
    .contact_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 2em;   
        letter-spacing: .17rem;
        line-height: 1em;
        margin: .15em 0;
        padding: 0 0 0 6rem;
        max-width: 40rem;
    }
    .contact_ul {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.3em;
        letter-spacing: .03em;  
        line-height: 1.2em;             
        padding: 0 0 0 6rem;  
        max-width: 26rem;      
    } 
    .contact_copy {  
        font-family: 'Montserrat', sans-serif;     
        font-size: 1.4em;
        letter-spacing: .03em;  
        line-height: 1.4em;
        margin: .6rem 0 0;      
        max-width: 30rem;
        padding: 0 0 0 3rem;
    }
   
}

/*---------------SMALL MOBILE--------------------*/
@media screen and (max-width: 479.5px) and (min-width: 319px){  
    .contact_section_background {
        background-attachment: scroll;  
    }
    .contact_content_container {
        width: 100vw;
        height: 100vh;
    }
    .contact_headline {
        font-family: 'Playfair Display', serif;
        font-size: 2.1em;
        line-height: 1em;
        letter-spacing: .15rem;
        max-width: 14rem; 
        padding: 0 0 0 3rem;       
    }
    .contact_headline_2 {  
        font-family: 'Playfair Display', serif;
        font-size: 1em;   
        letter-spacing: .01em;
        line-height: 1em;
        margin: .15rem 0;
        padding: 0 0 0 3rem;
        max-width: 22rem;
    }
    .contact_ul {     
        font-family: 'Montserrat', sans-serif;    
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;             
        padding: 0 0 0 4rem;        
    } 
    .contact_copy {   
        font-family: 'Montserrat', sans-serif;      
        font-size: .9em;
        letter-spacing: .03em;  
        line-height: 1.2em;
        margin: .6rem 0 0;     
        padding: 0 0 0 2em;
        max-width: 22rem;
    } 
  

}